@tailwind base;
@tailwind components;
@tailwind utilities;

@import '@de-electron/design-tokens/dist/web/__all';

@import './electron-overrides.scss';

@font-face {
  font-family: Roboto;
  src: url(assets/fonts/Roboto-Regular.ttf) format("truetype");
}

* {
  font-family: 'Roboto' !important;
}



// TODO: convert to tailwind?
.clickable {
    cursor: pointer;
    user-select: none;
    &:hover{
      filter: brightness(80%);
    }
}