.de-footer * {
    align-items: center;
    display:flex;
    flex-direction: row;
    font-size: 16px !important;
    text-align: left;
    @media only screen and (max-width: 640px) {
        font-size: 12px !important;
        flex-direction: column;
    }
  }


.de-stepper {

  @media only screen and (max-width: 640px) {
    margin: 0 -60px !important;
  }
 

    &__group {
      left: 0 !important;
    }
  
    &__text {
      color: #696969 !important;
    }
  
    &__numbered {
      display: none;
    }
  
    &__item {
      width: 16rem;
      list-style: none !important;
  
      &::after {
        transition: all 0.1s !important;
      }
  
      &--active {
        &::after {
          transition: all 0.1s 0.1s !important;
        }
  
        .de-stepper__icon {
          height: 28px !important;
          width: 28px !important;
          border: 2px solid #00853f !important;
          background: #00853f !important;
          transition: all 0.1s 0.1s !important;
          margin-bottom: unset !important;
        }
  
        .de-stepper__text {
          color: #696969 !important;
        }
      }
  
      &--complete {
        &::after {
          background-color: #00853f !important;
        }
  
        .de-stepper__icon {
          height: 28px !important;
          width: 28px !important;
          border: 2px solid #00853f !important;
          background: #00853f !important;
          margin-bottom: unset !important;
        }
      }
    }
    
  }

/* DE-One Logo Edits */
#dukeEnergyOneLogo {
  @media screen and (max-width: 375px) {
  }
}